



import { Component, Mixins } from 'vue-property-decorator'
import Challenge from '@/components/organisms/study/Challenge.vue'
import Contents1 from '@/views/student/study/Contents1.vue'

@Component({
  components: {
    Challenge,
  },
})
export default class Challenge2 extends Mixins(Contents1) {}
